@use '../../vars' as *;

@layer edwin {
  @mixin animate($animation, $duration, $method, $times) {
    & {
      animation: $animation $duration $method $times;
    }
  }

  @mixin keyframes($name) {
    @keyframes #{$name} {
      @content;
    }
  }

  .spinnerContainer {
    display: block;
    position: relative;
    margin: 0 auto 10px;
    width: 50px;
    height: 50px;
    pointer-events: none;

    @include keyframes(rotateSpinner) {
      100% {
        transform: rotate(360deg);
      }
    }

    @include animate(rotateSpinner, 1568ms, linear, infinite);
  }

  .spinnerLayer {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    border-color: '#5392b7';
    animation-fill-mode: both;

    @include keyframes(rotateFillUnfill) {
      12.5% {
        transform: rotate(135deg);
      }

      25% {
        transform: rotate(270deg);
      }

      37.5% {
        transform: rotate(405deg);
      }

      50% {
        transform: rotate(540deg);
      }

      62.5% {
        transform: rotate(675deg);
      }

      75% {
        transform: rotate(810deg);
      }

      87.5% {
        transform: rotate(945deg);
      }

      100% {
        transform: rotate(1080deg);
      }
    }

    @include animate(rotateFillUnfill,
      5332ms,
      cubic-bezier(0.4, 0, 0.2, 1),
      infinite);
  }

  .circleClipper {
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;
  }

  .circle {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 200%;
    border-width: 5px;
    border-style: solid;
    border-color: $color-base-200;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    animation: none;
  }

  .circleLeft {
    @extend .circle;
    border-right-color: transparent !important;
    transform: rotate(129deg);
    animation-fill-mode: both;

    @include keyframes(leftSpin) {
      0% {
        transform: rotate(130deg);
      }

      50% {
        transform: rotate(-5deg);
      }

      100% {
        transform: rotate(130deg);
      }
    }

    @include animate(leftSpin, 1333ms, cubic-bezier(0.4, 0, 0.2, 1), infinite);
  }

  .circleRight {
    @extend .circle;
    left: -100%;
    border-left-color: transparent !important;
    transform: rotate(129deg);
    animation-fill-mode: both;

    @include keyframes(rightSpin) {
      0% {
        transform: rotate(-130deg);
      }

      50% {
        transform: rotate(5deg);
      }

      100% {
        transform: rotate(-130deg);
      }
    }

    @include animate(rightSpin, 1333ms, cubic-bezier(0.4, 0, 0.2, 1), infinite);
  }

  /**
  * Patch the gap that appear between the two adjacent div.circle-clipper while the
  * spinner is rotating (appears on Chrome 38, Safari 7.1, and IE 11).
  */
  .gapPatch {
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 45%;
    width: 10%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;
  }

  .gapCircle {
    @extend .circle;
    left: -450%;
    width: 1000%;
  }
}